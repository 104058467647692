import { inject, Injectable } from '@angular/core';
import { first, map, filter } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { CheckChecklistRepository } from './checklist.repository';
import { CheckChecklistDataRepository } from '../checklist-data/checklist-data.repository';
import { CheckChecklistData } from '../checklist-data/types/checklist-data';

@Injectable({ providedIn: 'root' })
export class ChecklistSubmitFacade {
  private checklistRepo = inject(CheckChecklistRepository);
  private checklistDataRepo = inject(CheckChecklistDataRepository);

  getChecklistDataForSubmit(checklistId: number): Observable<CheckChecklistData> {
    return combineLatest([
      this.checklistDataRepo.getFullChecklistData(checklistId).pipe(filter(x => x != null), first()),
      this.checklistRepo.getForFill(checklistId).pipe(filter(x => x != null), first())
    ])
      .pipe(
        map(([checklistData, fillChecklist]) => {
          return {
            ...checklistData,
            Items: checklistData.Items?.filter(x => fillChecklist.items.some(y => y.Id === x.ItemId && y.fulfilledDependency))
              .filter(x => x.ItemData !== "" && x.ItemData !== null)
          }
        })
      )
  }

}
